.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88svh;
  color: aliceblue;
}
.error img {
  width: 350px;
}
.error_block {
  width: 100%;
  border-radius: 15px;
  background-color: #35373b;
  margin-bottom: 20px;
  padding: 20px 38px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: onLoad 0.3s ease-out;
}
.error_block h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}
.error_reload {
  border: 0;
  display: block;
  border-radius: var(--radius25);
  transition: all 0.3s ease;
  background: linear-gradient(45deg, rgb(97, 87, 255), rgb(238, 73, 253));
  padding: 10px 60px;
  border-radius: 20px;
  color: var(--textT-color);
}
.error_reload:hover {
  transform: translateY(-3px);
  opacity: 0.9;
}
.errorText {
  margin-right: 200px;
}
.errorTextWow {
  display: block;
  font-weight: 600;
  font-style: italic;
  font-size: 18px;
  opacity: 0.7;
  -webkit-text-decoration: underline 0.5px solid;
  -moz-text-decoration: underline 0.5px solid;
  text-decoration: underline 0.5px solid;
}
.errorTextSorry {
  font-style: italic;
  font-size: var(--font20);
  margin-top: 30px;
  font-weight: 500;
  margin-bottom: 30px;
  width: 400px;
}
.errorText h2 {
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
}
.errorText h2 b {
  font-weight: 600;
  -webkit-text-decoration: underline 3px;
  -moz-text-decoration: underline 3px;
  text-decoration: underline 3px;
  font-style: oblique;
}

@media (max-width: 1100px) {
  .errorText {
    margin-right: 50px;
  }
}
@media (max-width: 970px) {
  .error {
    flex-wrap: wrap-reverse;
    height: 70svh;
  }
  .error img {
    width: 300px;
  }
}
@media (max-width: 725px) {
  .error {
    height: auto;
  }
  .errorMobileCenter {
    height: 90svh;
    display: grid;
    place-items: center;
  }
  .errorTextWow {
    margin: 20px 0 5px 0;
    font-size: var(--font20);
  }
  .error p,
  .error h2 {
    text-align: center;
  }
  .error button {
    margin: 0 auto;
    padding: 12px 80px;
  }
  .error img {
    width: 300px;
  }
}
@media (max-width: 670px) {
  .errorText {
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .errorMobileCenter {
    height: 75svh;
  }
  .errorTextSorry {
    width: auto;
  }
  .error img {
    width: 200px;
  }
  .error h2 {
    font-size: 40px;
  }
}
@media (max-height: 670px) {
  .errorMobileCenter {
    height: 85svh;
  }
}
@keyframes onLoad {
  0% {
    transform: translateY(5px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media (max-width: 1760px) {
  .Block h1 {
    font-size: 21px;
  }
  .Block {
    margin-bottom: 15px;
  }
}
@media (max-width: 912px) {
  .Block h1 {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=error.module.css.map */