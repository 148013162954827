.lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 100%;
  padding: 6px 20px;
  background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  color: white;
  transform: skew(-5deg);
  border: 0;

  i {
    margin-right: 10px;
  }
  p {
    font-size: var(--font18);
  }
}

.outLang {
  margin-right: 15px;
}

@media (max-width: 512px) {
  .lang {
    i {
      margin-right: 0;
    }
    p b {
      display: none;
    }
  }
}
