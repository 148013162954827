.footer {
  width: calc(100% - 264px);
  padding: 8px 0;
  position: fixed;
  bottom: 0;
  left: 264px;
  background-color: var(--main_bg);
  bottom: 0;
  z-index: 10;
  display: flex;
  color: var(--text);
  font-size: 14px;
}
.footer button {
  background: transparent;
  transform: skew(-5deg);
  color: white;
  padding: 2px 10px;
  margin-left: 20px;
  border: 0;
}
.footer a {
  color: white;
}

@media (max-width: 768px) {
  .footer {
    width: 100%;
    left: 0;
  }
}/*# sourceMappingURL=Footer.module.css.map */