.linear_activity {
  overflow: hidden;
  width: 100%;
  height: 4px;
  background-color: #b3e5fc;
  margin: 20px auto;
  position: absolute;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    background-color: #03a9f4;
    animation: indeterminate_first 1.5s infinite ease-out;
  }
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    background-color: #4fc3f7;
    animation: indeterminate_second 1.5s infinite ease-in;
  }
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
}
