.error {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 88svh;
  color: aliceblue;

  img {
    width: 350px;
  }

  &_block {
    width: 100%;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: #35373b;
    margin-bottom: 20px;
    padding: 20px 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    -webkit-animation: onLoad 0.3s ease-out;
    -moz-animation: onLoad 0.3s ease-out;
    -o-animation: onLoad 0.3s ease-out;
    animation: onLoad 0.3s ease-out;
    h1 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 40px;
      text-align: center;
    }
  }

  &_reload {
    border: 0;
    display: block;
    -webkit-border-radius: var(--radius25);
    -moz-border-radius: var(--radius25);
    border-radius: var(--radius25);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: -webkit-linear-gradient(
      45deg,
      rgb(97, 87, 255),
      rgb(238, 73, 253)
    );
    background: -moz-linear-gradient(
      45deg,
      rgb(97, 87, 255),
      rgb(238, 73, 253)
    );
    background: -o-linear-gradient(45deg, rgb(97, 87, 255), rgb(238, 73, 253));
    background: linear-gradient(45deg, rgb(97, 87, 255), rgb(238, 73, 253));
    padding: 10px 60px;
    border-radius: 20px;
    color: var(--textT-color);
    &:hover {
      -webkit-transform: translateY(-3px);
      -moz-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      -o-transform: translateY(-3px);
      transform: translateY(-3px);
      opacity: 0.9;
    }
  }

  &Text {
    margin-right: 200px;
    &Wow {
      display: block;
      font-weight: 600;
      font-style: italic;
      font-size: 18px;
      opacity: 0.7;
      -webkit-text-decoration: underline 0.5px solid;
      -moz-text-decoration: underline 0.5px solid;
      text-decoration: underline 0.5px solid;
    }

    &Sorry {
      font-style: italic;
      font-size: var(--font20);
      margin-top: 30px;
      font-weight: 500;
      margin-bottom: 30px;
      width: 400px;
    }

    h2 {
      font-size: 50px;
      letter-spacing: 2px;
      font-weight: 600;

      b {
        font-weight: 600;
        -webkit-text-decoration: underline 3px;
        -moz-text-decoration: underline 3px;
        text-decoration: underline 3px;
        font-style: oblique;
      }
    }
  }
}

@media (max-width: 1100px) {
  .errorText {
    margin-right: 50px;
  }
}

@media (max-width: 970px) {
  .error {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    height: 70svh;

    img {
      width: 300px;
    }
  }
}

@media (max-width: 725px) {
  .error {
    height: auto;

    &MobileCenter {
      height: 90svh;
      display: -ms-grid;
      display: grid;
      place-items: center;
    }

    &TextWow {
      margin: 20px 0 5px 0;
      font-size: var(--font20);
    }

    p,
    h2 {
      text-align: center;
    }

    button {
      margin: 0 auto;
      padding: 12px 80px;
    }

    img {
      width: 300px;
    }
  }
}

@media (max-width: 670px) {
  .errorText {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .error {
    &MobileCenter {
      height: 75svh;
    }
    &TextSorry {
      width: auto;
    }
    img {
      width: 200px;
    }

    h2 {
      font-size: 40px;
    }
  }
}

@media (max-height: 670px) {
  .errorMobileCenter {
    height: 85svh;
  }
}

@-webkit-keyframes onLoad {
  0% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateY(-0px);
    transform: translateY(-0px);
    opacity: 1;
  }
}

@-moz-keyframes onLoad {
  0% {
    -moz-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 0.8;
  }
  100% {
    -moz-transform: translateY(-0px);
    transform: translateY(-0px);
    opacity: 1;
  }
}

@-o-keyframes onLoad {
  0% {
    -o-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 0.8;
  }
  100% {
    -o-transform: translateY(-0px);
    transform: translateY(-0px);
    opacity: 1;
  }
}

@keyframes onLoad {
  0% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateY(-0px);
    -moz-transform: translateY(-0px);
    -o-transform: translateY(-0px);
    transform: translateY(-0px);
    opacity: 1;
  }
}

@media (max-width: 1760px) {
  .Block h1 {
    font-size: 21px;
  }

  .Block {
    margin-bottom: 15px;
  }
}

@media (max-width: 912px) {
  .Block h1 {
    margin-bottom: 30px;
  }
}
