.langAbsolute {
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.langAbsolute h3 {
  text-align: center;
}
.langAbsolute_block {
  transform: skew(-4deg);
  background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  display: block;
  height: auto;
  padding: 20px;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 16px 20px rgb(43, 3, 255);
}

.langChange {
  margin-top: 10px;
  border-radius: var(--radius15);
}
.langChange li {
  background-color: var(--langUl-color);
  border-radius: var(--radius10);
  padding: 5px 10px;
  font-size: var(--font18);
  cursor: pointer;
  text-align: center;
  display: flex;
  margin-top: 10px;
}
.langChange li img {
  display: block;
  margin: auto 0;
}
.langChange li p {
  margin-left: 5px;
  margin-top: -3px;
  font-size: var(--font24);
}

@media (max-width: 912px) {
  .langChange {
    height: auto;
    border-radius: var(--radius15);
    padding-left: 5px;
    padding-right: 5px;
  }
  .langChange li {
    font-size: var(--font24) !important;
    margin-bottom: 10px;
  }
}/*# sourceMappingURL=LangAbdolute.module.css.map */